import { MainSubStructureModel, request, TimeStampFormatter, NormalProgramme } from 'egenie-utils';
import type { PureData } from 'egenie-utils';
import React from 'react';
import type { MainItem } from './types';

export class Store {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.mainSubStructureModel.gridModel.onQuery();
  }

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          name: '仓库名称',
          key: 'warehouse_name',
          width: 200,
        },
        {
          name: '仓库编码',
          key: 'warehouse_no',
          width: 200,
        },
        {
          name: '仓库类型',
          key: 'warehouse_type',
          width: 200,
        },
        {
          name: '主仓',
          key: 'is_master',
          width: 60,
        },
        {
          name: '云仓',
          key: 'is_cloud',
          width: 60,
        },
        {
          name: '多库位',
          key: 'is_postion_enabled',
          width: 80,
        },
        {
          name: '助记码',
          key: 'shortcut_key',
          width: 200,
        },
        {
          name: '是否启用',
          key: 'is_enabled',
          width: 80,
        },
        {
          name: '更新时间',
          key: 'last_update_time',
          width: 200,
          formatter: ({ row }) => <TimeStampFormatter value={row.last_update_time}/>,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'warehouse_id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      pageSize: 50,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PureData<MainItem>>({
          url: '/api/cloud/baseinfo/rest/warehouse/querybyctgr',
          method: 'POST',
          data: new URLSearchParams({
            ...filterParams,
            ...rest,
            category_no: '004',
          }),
        })
          .then((info) => ({
            status: 'Successful',
            data: info,
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        field: 'search_key',
        label: '搜索条件',
        value: 'warehouse_no',
        data: [
          {
            value: 'warehouse_no',
            label: '仓库编码',
          },
          {
            value: 'warehouse_name',
            label: '仓库名称',
          },
          {
            value: 'shortcut_key',
            label: '助记码',
          },
        ],
        labelWidth: 0,
      },
      {
        type: 'input',
        field: 'search_value',
        label: '搜索值',
        labelWidth: 0,
      },
    ],
    count: 4,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}
